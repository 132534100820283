let source;

export function initEvents() {
    console.log("Initializing events");
    source = new EventSource(`/api/events`);
}

export function stopEvents() {
    if (source) {
        source.close();
        source = null;
    }
}

export function addEventListener(eventType, callback) {
    function cb(event) {
        const data = JSON.parse(event.data);
        const type = event.type;
        console.info("Received event of type", type, "with data", data);
        callback({...data, type});
    }
    return source.addEventListener(eventType, cb);
}

