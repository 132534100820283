"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _isReadableStream = require("./isReadableStream");

var _isReadableStream2 = _interopRequireDefault(_isReadableStream);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

// flattens all children of a react element into a single array. usually,
// we could use React.Children.map here, but that function throws when it
// encounters a stream.
var flattenChildren = function flattenChildren(children) {
	if (!children) {
		return [];
	}
	if (typeof children === "string" || (0, _isReadableStream2.default)(children)) {
		return [children];
	}

	var result = [];
	children.forEach(function (child) {
		return result.push.apply(result, _toConsumableArray(flattenChildren(child)));
	});
	return result;
};

exports.default = flattenChildren;