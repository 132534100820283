export class HttpException {
    constructor(response) {
        this.code = response.status;
        this.error = response.statusText;
        
    }

    toString() {
        return this.error;
    }
}
