import React from "react";
import {invert, SortDirectionEnum, SortOrderEnum} from "../../dbo/SortOrderEnum";

export interface SortBarProps {
    onHideSortBar: () => void;
    currentOrder: SortOrderEnum;
    currentDirection: SortDirectionEnum;
    onSortChanged: (order: SortOrderEnum, direction: SortDirectionEnum) => void;
}


const sortOrders : SortOrderEnum[] = ['creationDate', 'modificationDate' ,'dueDate'];
export function SortBar(props: SortBarProps) {
    function getSortIcon(so: SortOrderEnum, ) {
        if (so !== props.currentOrder) {
            return <i className='fa fa-fw'/> ;
        }
        switch( props.currentDirection ) {
            case "asc":
                return <i className='fa fa-fw fa-sort-up'/>
            case "desc":
                return <i className='fa fa-fw fa-sort-down'/>;
        }
    }


    function getClassName(so : SortOrderEnum) {
        if (so === props.currentOrder) {
            return "selected";
        } else {
            return "";
        }
    }

    function getCallback(so : SortOrderEnum) {
        if (so === props.currentOrder) {
            return () => props.onSortChanged(so, invert(props.currentDirection));
        } else {
            return () => props.onSortChanged(so, 'desc');
        }
    }

    function getName(so: SortOrderEnum) {
        switch(so) {
            case "creationDate":
                return "Creation";
            case "dueDate":
                return "Due";
            case "modificationDate":
                return "Modification"
        }
    }

    return <div className="header">
        <button onClick={props.onHideSortBar}><i className='fa fa-fw fa-arrow-left'></i></button>
        {sortOrders.map( so =>
            <button key={so} onClick={getCallback(so)} className={getClassName(so)}>{getSortIcon(so)}{getName(so)}</button>
        )}
    </div>;


}