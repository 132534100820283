import React, {ForwardedRef, MutableRefObject, Ref, useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {useMutationObserver} from "@react-hooks-library/core";

export interface ContentEditableProps {
    html: string;
    className: string;
    onSelectionChange: (selection: Selection) => void;
    onChange: (args: {
        target: {
            value: any
        }
    }) => void;
}

interface State {
    html: string;
}

export function ContentEditable(props: ContentEditableProps, ref: ForwardedRef<HTMLDivElement>) {
    // We don't want to render directly props.html, because when we are editing we end up
    // replacing the current html with an identical one, but in the process we lose the caret position
    // So the idea is

    // Keep a local copy of the html to render
    // while we edit, the local copy remains unchanged, so we don't overwrite it and lose the caret position
    // if the change is external (i.e. the innerHTML of the div is different than props.html)
    // then update the local copy of the state, forcing the update of the innerHTML
    const    [state, setState] = useState( { html : props.html });

    if ( ref  && (ref as any).current ) {
        const currentHtml = (ref as any).current.innerHTML;
        if (currentHtml != props.html) {
            console.log("change1", currentHtml);
            console.log("change2", props.html);
            console.log("change3", state.html);
            setState( state => {
                console.log("Updating html");
                return { html: props.html };
            });
        }
    }
    function emitChange() {
        if (!ref) {
            return;
        }
        const content = (ref as any).current;
        if (!content) {
            return;
        }
        var html = content.innerHTML;
        if (props.onChange && html !== props.html) {
            props.onChange({
                target: {
                    value: html
                }
            });
        }
    }

    return <div id="contenteditable"
                ref={ref}
                    onInput={() => emitChange()}
                    onBlur={() => emitChange()}
                    contentEditable
                    className={props.className}
                    dangerouslySetInnerHTML={{__html: state.html}}></div>;

}