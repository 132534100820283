import React, {useEffect} from 'react';


interface SearchBarProps {
    onHideSearchField: () => void;
    searchText: string;
    onSearchKeyPress: (event: any) => void;
    onSearchChange: (event: any) => void;
}

export function SearchBar(props: SearchBarProps) {
    useEffect(() => {
        const searchbox = document.getElementById("searchbox");
        if (searchbox) {
            searchbox.focus();
            // workaround for iOS (not sure if it works)
            searchbox.setAttribute('readonly', 'readonly'); // Set the element to readonly temporarily
            searchbox.removeAttribute('readonly'); // Remove the readonly attribute after a small delay

        };
    }, []);

    return <div className="header">
        <button onClick={props.onHideSearchField}><i className='fa fa-fw fa-arrow-left'></i></button>
        <input type="text" placeholder="Search..."
               id="searchbox"
               value={props.searchText}
               onKeyDown={props.onSearchKeyPress}
               onChange={e => props.onSearchChange(e.target.value)} className="search"></input>
    </div>;

}
