"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _flattenChildren = require("./flattenChildren");

var _flattenChildren2 = _interopRequireDefault(_flattenChildren);

var _rawHtmlFactory = require("./rawHtmlFactory");

var _rawHtmlFactory2 = _interopRequireDefault(_rawHtmlFactory);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var getChildrenAsString = function getChildrenAsString(children) {
	return (0, _flattenChildren2.default)(children).join("");
};

exports.default = (0, _rawHtmlFactory2.default)(getChildrenAsString);