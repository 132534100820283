import React, {useState} from 'react';
import './label-modal.css';
import {Modal} from './shared/Modal';
import {LabelDbo} from "../dbo/LabelDbo";

interface LabelSelectModalProps {
    labels: LabelDbo[];
    selected: any[];
    onCloseClick: (label: any) => void;
}

export function LabelSelectModal(props: LabelSelectModalProps) {
    const selected: { [key: string]: any } = {};
    const available: { [key: string]: any } = {};

    const labels = props.labels;
    labels.forEach(label => {
        available[label.id] = true;
    });

    props.selected.filter(l => available[l]).forEach(s => {
        selected[s] = true;
    });
    const [state, setState] = useState({selected, labels});

    function toggleSelected(labelId: string) {
        const value = !state.selected[labelId];
        const selected = {...state.selected, [labelId]: value};
        setState({...state, selected});
    }

    function renderIcon(labelId: string) {
        if (state.selected[labelId]) {
            return <i className="label-modal-element clickable fa fa-check-square"
                      onClick={() => toggleSelected(labelId)}/>
        } else {
            return <i className="label-modal-element clickable fa fa-square"
                      onClick={() => toggleSelected(labelId)}/>
        }
    }

    function renderLabels() {
        return state.labels.map((label: any) => {
            return <div className="label-modal-row" key={label.id}>
                <span className="label-modal-element">{label.description}</span>
                {renderIcon(label.id)}
            </div>;
        });
    }

    function getSelectedLabels() {

        return Object.keys(state.selected).filter( key => state.selected[key] );
    }

    const close = () => (props as any).onCloseClick(getSelectedLabels());
    return <Modal onOutsideClick={close} background="transparent">
        <div>Choose labels</div>
        <div>
            {renderLabels()}
        </div>
        <div>
            <div className="button-row">
                <button onClick={close}>Close</button>
            </div>
        </div>
    </Modal>;
}
