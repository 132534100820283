{
  "name": "frontend",
  "version": "0.8.9",
  "private": true,
  "dependencies": {
    "@types/react": "^17.0.48",
    "@types/react-dom": "^17.0.17",
    "@types/react-router-dom": "^5.1.3",
    "normalize.css": "^8.0.1",
    "react": "^17.0.2",
    "react-async-hook": "^4.0.0",
    "react-dom": "^17.0.2",
    "react-raw-html": "^0.4.0",
    "react-router-dom": "^5.1.2",
    "react-shadow": "^19.0.2",
    "typescript": "^4.2.4"
  },
  "scripts": {
    "start": "parcel src/index.html",
    "build": "parcel build src/index.html"
  },
  "eslintConfig": {
    "extends": "react-app"
  },
  "engines": {
    "node": ">= 16"
  },
  "proxy": "http://localhost:3004",
  "browserslist": [
    ">0.2%",
    "not dead",
    "not ie <= 11",
    "not op_mini all"
  ],
  "devDependencies": {
    "@fortawesome/fontawesome-free": "^5.12.1",
    "@parcel/optimizer-terser": "^2.9.3",
    "@parcel/packager-raw-url": "^2.9.3",
    "@parcel/transformer-webmanifest": "^2.9.3",
    "@types/dom-speech-recognition": "^0.0.1",
    "parcel": "^2.9.3",
    "process": "^0.11.10"
  },
  "type": "module"
}
