import React, {useState} from 'react';
import './label-modal.css';
import {LabelDbo, NewLabelDbo} from "../dbo/LabelDbo";

interface LabelEditModalProps {
    onLabelChanged: (action: string, label: NewLabelDbo) => void,
    labels: LabelDbo[],
    show: boolean,
    onCloseClick: () => void
}

interface LabelEditModalState {
    newLabelDescription: string,
    editing: {
        [id: string]: string
    }
}

const initialState: LabelEditModalState = {
    newLabelDescription: '',
    editing: {}
};

export function LabelEditModal(props: LabelEditModalProps) {
    const [state, setState] = useState(initialState);

    function handleDeleteLabel(label: LabelDbo) {
        props.onLabelChanged('delete', label);
    }

    function setEditing(label: LabelDbo, value: string | undefined) {
        const editing = {...state.editing};
        if (value) {
            editing[label.id] = value;
        } else {
            delete editing[label.id];
        }
        setState({...state, editing});
    }

    function handleEditLabel(label: LabelDbo) {
        setEditing(label, label.description);
    }

    function handleSaveLabel(label: LabelDbo) {
        props.onLabelChanged('edit', {...label, description: state.editing[label.id]});
        setEditing(label, undefined);
    }

    function handleEditedLabelChanged(label: LabelDbo, newValue: string) {
        setEditing(label, newValue);
    }

    function renderLabels() {
        return props.labels.map(label => {
            if (state.editing[label.id]) {
                return <div className="label-modal-row" key={label.id}>
                    <input className="label-modal-element" value={state.editing[label.id]}
                           onChange={event => handleEditedLabelChanged(label, event.target.value)}/>
                    <i className="label-modal-element clickable fa fa-check" onClick={() => handleSaveLabel(label)}/>
                </div>;

            } else {
                return <div className="label-modal-row" key={label.id}>
                    <span className="label-modal-element">{label.description}</span>
                    <i className="label-modal-element clickable fa fa-pen" onClick={() => handleEditLabel(label)}/>
                    <i className="label-modal-element clickable fa fa-trash" onClick={() => handleDeleteLabel(label)}/>

                </div>;
            }
        });
    }

    function handleLabelDescriptionChange(event: any) {
        setState({...state, newLabelDescription: event.target.value});
    }

    function handleSaveNewLabel(description: string) {
        props.onLabelChanged('create', {description});
        setState({...state, newLabelDescription: ''});
    }

    function renderNewLabel() {
        return <div className="label-modal-row">
            <input className="label-modal-element" id="new-label-description" type="text"
                   value={state.newLabelDescription} onChange={e => handleLabelDescriptionChange(e)}/>
            <i className="label-modal-element clickable fa fa-check"
               onClick={() => handleSaveNewLabel(state.newLabelDescription)}/>
        </div>;
    }

    if (props.show) {
        return <div>
            <div className="modal-container">
                <div className="modal">
                    <div className="modal-header">Edit labels</div>
                    <div className="modal-body">
                        {renderNewLabel()}
                        {renderLabels()}
                    </div>
                    <div className="modal-footer">
                        <div className="button-row">
                            <button onClick={props.onCloseClick}>Close</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>;

    } else {
        return null;
    }
}
