import React, {CSSProperties, forwardRef, MutableRefObject, Ref, useEffect, useRef, useState} from 'react';

import RawHtml from "react-raw-html";

import './Note.css';

import {IconList} from './IconList';
import {ContentEditable} from './ContentEditable';
import {LabelSelectModal} from "./LabelSelectModal";
import {NoteDbo} from "../dbo/NoteDbo";
import {LabelDbo} from "../dbo/LabelDbo";
import {IconDbo} from "../dbo/IconDbo";
import Root from 'react-shadow';
import {SpeechService} from "../service/SpeechService";

interface NoteProps {
    note: NoteDbo;
    labels: LabelDbo[];
    selected: boolean;
    onClick: (event: Event) => void;
    onSaveClick: (note: NoteDbo) => void;
    onDeleteClick: (event: Event) => void;
}

interface NoteState {
    title: string,
    content: string,
    color: any,
    selectedLabels: LabelDbo[],
    labels: LabelDbo[],
    showPalette: boolean,
    showLabels: boolean,
    originalNote: NoteDbo,
    recording: boolean;
}


const CE = forwardRef(ContentEditable);

const EMPTY_SPEECH_NODES: Text[] = [];

export function Note(props: NoteProps) {

    const initialNoteState: NoteState = {
        title: props.note.title || '',
        content: props.note.content || '',
        color: props.note.color,
        selectedLabels: props.note.labels,
        labels: props.labels,
        showPalette: false,
        showLabels: false,
        originalNote: props.note,
        recording: false
    };

    const [state, setState] = useState(initialNoteState);
    const titleRef: Ref<HTMLInputElement> = useRef(null);
    const contentRef: Ref<HTMLDivElement> = useRef(null);
    const [speechNodes, setSpeechNodes] = useState(EMPTY_SPEECH_NODES);

    useEffect(() => {
        if (props.selected && titleRef.current != null) {
            titleRef.current.focus();
        }
    }, [titleRef, props.selected]);

    if (props.note !== state.originalNote) {
        // but only if we not already editing it
        if (!props.selected) {
            setState({
                ...state,
                title: props.note.title,
                content: props.note.content
            });
        }
    }


    function setLabels(selectedLabels: LabelDbo[]) {
        const result = {...state, selectedLabels};
        setState(result);
    }


    function handleChange(what: string, event: any) {
        switch (what) {
            case 'title':
                setState({...state, title: event.target.value});
                break;
            case 'content':
                setState({...state, content: event.target.value});
                break;
            default:
                console.log("Invalid note target", what);
        }
    }


    function handleNoteClick(event: any) {
        event.stopPropagation();
        if (props.selected) {
            return;
        }
        props.onClick(event);
    }

    function handleUndoClick(event: Event) {
        setState({
            ...state,
            title: props.note.title || '',
            content: props.note.content || ''
        })
    }

    function handleRecordStartClick(event: Event) {
        console.log("Start recording");

        function getSelection() {
            if (!contentRef) {
                return;
            }
            const element = (contentRef as any).current as HTMLDivElement;
            let selection: Selection = element.parentNode && (element.parentNode as any).getSelection();
            if (selection) {
                return selection;
            } else {
                console.error("No selection !");
            }
            return null;
        }

        function onText(text: string[]) {
            console.log("On Text", text);
            for (let i = 0; i < text.length; ++i) {
                if (speechNodes[i]) {
                    const oldNode = speechNodes[i];
                    const newNode = document.createTextNode(text[i]);
                    console.log("Speech node",i,"present:", oldNode, `, replacing [${oldNode.data}] with [${text[i]}]`);

                    oldNode.parentNode?.replaceChild(newNode, oldNode);
                    speechNodes[i] = newNode;
                } else {
                    console.log("Creating new speech node",i,"with content", text[i]);
                    const txt = text[i];
                    const selection = getSelection();
                    if (!selection) {
                        continue;
                    }
                    if (selection.rangeCount < 1) {
                        continue;
                    }

                    const range = selection.getRangeAt(0);
                    console.log("Selection", selection);
                    console.log("range", range);
                    const textNode = document.createTextNode(txt);
                    range.insertNode(textNode);
                    speechNodes[i] = textNode;
                }
            }
            for (let i = text.length; i < speechNodes.length; ++i) {
                const sn = speechNodes[i];
                sn.parentNode?.removeChild(sn);
                delete speechNodes[i];
            }
            speechNodes.length = text.length;
            setSpeechNodes(speechNodes);

        }

        function onEnd() {
            setState(state => {
                return {...state, recording: false};
            });
        }


        SpeechService.startRecording({onText, onEnd});
        setState(state => {
            return {...state, recording: true};
        });
    }

    function handleRecordStopClick(event: Event) {
        stopRecording();
    }

    function stopRecording() {
        console.log("Stop recording");
        SpeechService.stopRecording();
        const content = (contentRef as any).current.innerHTML;
        setState(state => {
            return {...state, recording: false, content};
        });
        setSpeechNodes([]);
        return content;

    }

    function handleSelectionChange(newSelection: Selection) {
        console.log("Selection, ", newSelection);
    }


    function renderContent() {
        const editing = props.selected;
        if (editing) {
            /* FIXME */
            /* eslint-disable-next-line react/jsx-pascal-case  */
            return <Root.div className="note-content">
                <CE
                    ref={contentRef}
                    onSelectionChange={handleSelectionChange}
                    className="note-content"
                    html={state.content}
                    onChange={(e: any) => handleChange('content', e)}/>
            </Root.div>;
        } else if (props.note.content) {
            /* eslint-disable-next-line react/jsx-pascal-case  */
            return <Root.div className="note-content"><RawHtml.div> {props.note.content}</RawHtml.div></Root.div>;
        } else {
            return '';
        }
    }

    function renderTitle() {
        const editing = props.selected;
        if (editing) {
            return <input type="text" placeholder="Insert Title" value={state.title}
                          ref={titleRef}
                          onClick={e => e.stopPropagation()} onChange={e => handleChange('title', e)}/>;
        }
        if (state.title) {
            return <span>{state.title}</span>;
        } else {
            return <em>Untitled</em>;
        }
    }

    function onColorSelected(color: string) {
        setState({...state, color, showPalette: false});
    }

    function renderColorPalette() {
        const saturation = 'var(--palette-saturation)'
        const luminance = 'var(--palette-luminance)'
        const nColors = 6;
        const hueDelta = 360 / nColors;
        const hues = [];
        for (let i = 0; i < nColors; ++i) {
            hues.push(i * hueDelta);
        }
        const icons = hues.map(hue => {
            const color = `hsl( ${hue}, ${saturation}, ${luminance} )`;
            return {
                id: color,
                icon: state.color === color ? 'fa-check-circle' : 'fa-circle',
                enabled: true,
                callback: (e: any) => onColorSelected(color),
                color: color
            };
        });

        const backgroundColor = state.color ? {background: state.color} : {background: 'white'};

        if (state.showPalette) {
            return <div className="note-palette"><IconList style={backgroundColor} icons={icons}/></div>;
        } else {
            return '';
        }
    }

    function onShowLabelsClick() {
        const showLabels = !state.showLabels;
        const showPalette = false;
        setState({...state, showLabels, showPalette})
    }

    function onShowPaletteClick() {
        const showPalette = !state.showPalette;
        const showLabels = false;
        setState({...state, showPalette, showLabels});
    }

    function renderFooter() {
        const icons: IconDbo[] = [{
            id: 'save',
            icon: 'fa-check',
            enabled: props.selected,
            callback: ((e: any) => onSaveClick(state))
        }, {
            id: 'delete',
            icon: 'fa-trash',
            enabled: true,
            callback: props.onDeleteClick
        }, {
            id: 'label',
            icon: 'fa-tag',
            enabled: true,
            callback: ((e: any) => onShowLabelsClick())
        }, {
            id: 'color',
            icon: 'fa-palette',
            enabled: true,
            callback: ((e: any) => onShowPaletteClick())
        }, {
            id: 'undo',
            icon: 'fa-undo',
            enabled: props.selected,
            callback: ((e: any) => handleUndoClick(e))
        }, {
            id: 'speech-start',
            icon: 'fa-microphone',
            enabled: SpeechService.isSpeechRecognitionAvailable() &&  props.selected && !state.recording,
            callback: ((e: any) => handleRecordStartClick(e))
        }, {
            id: 'speech-stop',
            icon: 'fa-microphone-slash',
            enabled: props.selected && state.recording,
            callback: ((e: any) => handleRecordStopClick(e))
        }];

        return <div className="note-footer">
            {renderColorPalette()}

            <IconList icons={icons}/>
        </div>;
    }

    function onSaveClick(updatedState: NoteState) {
        let {title, content, color, selectedLabels} = updatedState;
        if (state.recording) {
            content = stopRecording();
        }
        props.onSaveClick({
            id: updatedState.originalNote.id, title, content, color, labels: selectedLabels,
            creationDate: new Date(), modificationDate: new Date()
        });
    }

    function sameLabels(oldLabels: LabelDbo[], newLabels: LabelDbo[]) {
        return false;
        // if (oldLabels.length !== newLabels.length) {
        //     return false;
        // }
        // for (let i=0; i<oldLabels.length; ++i) {
        //     if (oldLabels[i].id !== newLabels[i].id) {
        //         return false;
        //     }
        // }
        // return true;
    }

    function onLabelsSelectedClick(labels: LabelDbo[]) {
        if (sameLabels(state.selectedLabels, labels)) {
            console.log("Labels are the same, not saving");
        } else {
            console.log("Saving note with new labels");
            setLabels(labels);
            onSaveClick({...state, selectedLabels: labels});
        }
        onShowLabelsClick();
    }

    function renderLabelsModal() {
        if (state.showLabels) {
            return <LabelSelectModal labels={state.labels} selected={state.selectedLabels || []}
                                     onCloseClick={(labels: any) => onLabelsSelectedClick(labels)}
            />
        } else {
            return '';
        }
    }

    const className = props.selected ? "note selected" : "note";
    const selected = props.selected ? 'item selected' : 'item';
    const backgroundColor: CSSProperties = state.color ? {background: state.color} : {};

    return <div className={selected} onClick={event => onSaveClick(state)}>
        <div className={className} style={backgroundColor} onClick={e => handleNoteClick(e)}>
            <div className="note-title">
                {renderTitle()}
            </div>
            {renderContent()}
            {renderFooter()}
        </div>
        {renderLabelsModal()}
    </div>
        ;

}

