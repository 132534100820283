import './MainPage.css';
import React, {useEffect, useState} from 'react';
import {MenuState} from '../state';
import {
    closeModal,
    deleteItemAsyncGen,
    deselectItems,
    loadDataAsync,
    newItem,
    saveItemAsyncGen,
    searchText,
    selectItem,
    setItems,
    State,
    toggleSelectedLabel
} from '../actions/action';

import {Header} from '../component/header/Header';
import {Note} from '../component/Note';
import {NoteService} from '../service/NoteService';
import {SideMenu} from "../component/side-menu/SideMenu";
import {LabelEditModal} from "../component/LabelEditModal";
import {changeLabelAsyncGen, showLabelsModal} from "../actions/Labels";
import {NoteDbo} from "../dbo/NoteDbo";
import {LabelService} from "../service/Label";
import {useAsync} from "react-async-hook";
import {LabelDbo, NewLabelDbo} from "../dbo/LabelDbo";
import {ItemDbo} from "../dbo/ItemDbo";
import {SortDirectionEnum, SortOrderEnum} from "../dbo/SortOrderEnum";



export type StateUpdateIterator = AsyncIterator<Partial<State>, void, State>;

export const INITIAL_STATE: State = {
    version: 0,
    state: 'initial',
    message: null,
    items: [],
    filteredItems: [],
    filter: '',
    nextKey: 1,
    labels: [],
    currentLabel: null,
    menu: {
        shown: 'none'
    },
    modal: {
        labels: {
            show: false
        }
    },
    sortOrder: "modificationDate",
    sortDirection: "desc"
};



const sorters: {[key:string]: (a:ItemDbo, b:ItemDbo) => number } = {
    'modificationDate-asc': (a,b) => a.note.modificationDate.getTime() - b.note.modificationDate.getTime(),
    'modificationDate-desc': (a,b) => b.note.modificationDate.getTime() - a.note.modificationDate.getTime(),
    'creationDate-asc': (a,b) => a.note.creationDate.getTime() - b.note.creationDate.getTime(),
    'creationDate-desc': (a,b) => b.note.creationDate.getTime() - a.note.creationDate.getTime(),
    // 'dueDate-asc': (a,b) => a.note.dueDate.getTime() - b.note.dueDate.getTime(),
    // 'dueDate-desc': (a,b) => b.note.dueDate.getTime() - a.note.dueDate.getTime(),

}

function checkParams(state: State, match: any): Partial<State> {



    switch (match.path) {
        case "/":
            return deselectItems(state);
        case "/note":
            const urlParams = new URLSearchParams(window.location.search);
            const title = urlParams.get('title');
            const text = urlParams.get('text') || '';
            const url = urlParams.get('url') || '';
            return newItem(state, NoteService.fromPlainText(title, text, url));
        case "/note/:id":
            return selectItem(state, match.params['id']);
        default:
            console.error("Unknown path", match.path);
            return state;
    }
}

export const MainPage: React.FC<any> = (props: any) => {
    let [state, setState] = useState(INITIAL_STATE);



    const labels = useAsync(LabelService.getAll, []);
    const notes = useAsync(() => loadDataAsync(state), []);
    useEffect(() => {
        switch (labels.status) {
            case "loading":
            case "not-requested":
                break;
            case "error":
                setState({...state, error: labels.error?.message});
                break;
            case "success":
                setState((oldState: State) => {
                    const newState = notes.status === 'success' ? 'loaded' : oldState.state;
                    return {...oldState, state: newState, labels: labels.result as LabelDbo[]};
                });
        }
        /* FIXME */
        /* eslint-disable-next-line react-hooks/exhaustive-deps  */
    }, [labels.status]);

    useEffect(() => {
        switch (notes.status) {
            case "loading":
            case "not-requested":
                break;
            case "error":
                setState({...state, error: notes.error?.message});
                break;
            case "success":
                setState((oldState: State) => {
                    const newStatus = notes.status === 'success' ? 'loaded' : oldState.state;
                    const partialResult = setItems(notes.result as ItemDbo[], oldState.filter, oldState.currentLabel);
                    const result = Object.assign({}, oldState, partialResult, {state: newStatus});
                    return result;
                });
        }
        /* FIXME */
        /* eslint-disable-next-line react-hooks/exhaustive-deps  */
    }, [notes.status]);

    function updateState(update: Partial<State>): State {
        if (update === null || update === undefined || update === {}) {
            return state;
        }

        // We need to overwrite the state variable, since if we are called inside a
        // generator multiple times without any update to the component
        // Note that this may the case ALWAYS. If so we need to find a different way
        // to handle this then there would be no point in using a generator.
        state = Object.assign({}, state, update);
        state.version += 1;
        setState(state);
        console.log(state);
        console.log("Updated state with", update);
        return state;
    }

    async function updateStates(updates: AsyncIterator<Partial<State>, void, State>) {
        let next = await updates.next(state);
        while (!next.done) {
            let newState = updateState(next.value);
            next = await updates.next(newState);
        }
    }


    async function initialChecks() {
        const updatedState = state;
        const paramsData = checkParams(updatedState, props.match);
        updateState(paramsData);

    }

    useEffect(() => {
        initialChecks();
        /* FIXME */
        /* eslint-disable-next-line react-hooks/exhaustive-deps  */
    }, []);


    function handleItemClick(event: Event, noteId: any) {
        event.stopPropagation();
        updateState(selectItem(state, noteId));
    }

    function handleAddClick() {
        updateState(newItem(state, {title: null, content: null}));
    }

    function handleDeleteClick(event: Event, item: any) {
        event.stopPropagation();
        updateStates(deleteItemAsyncGen(state, item));
    }

    function handleSearchChange(text: string) {
        updateState(searchText(state, text));
    }


    function renderNotes() {
        const items = state.filteredItems.slice();
        items.sort(sorters[`${state.sortOrder}-${state.sortDirection}`] );
        return items.map(item => {
            return <Note note={item.note}
                         key={item.key}
                         selected={item.selected}
                         labels={state.labels}
                         onSaveClick={(note: NoteDbo) => updateStates(saveItemAsyncGen(state, item, note))}
                         onDeleteClick={(event: Event) => handleDeleteClick(event, item)}
                         onClick={(event: Event) => handleItemClick(event, item.note.id)}></Note>;
        });
    }

    function handleLabelSelected(label: any) {
        updateState(toggleSelectedLabel(state, label));
    }

    function handleLabelChanged(action: any, label: NewLabelDbo) {
        updateStates(changeLabelAsyncGen(state, action, label));
    }

    function handleEditLabels() {
        updateState(showLabelsModal(state));
    }

    function handleModalClose(modalName: any) {
        updateState(closeModal(state, modalName));
    }

    function handleShowLabelsClick(event: any) {
        let menu: MenuState;
        let newState;
        switch (state.menu.shown) {
            case "labels":
                menu = {...state.menu, shown: 'none'};
                newState = {...state, menu};
                setState(newState);
                return;
            default:
                menu = {...state.menu, shown: 'labels'};
                newState = {...state, menu};
                setState(newState);
                return;
        }
    }

    function handleSort(sortOrder: SortOrderEnum, sortDirection: SortDirectionEnum) {
        setState( state => ({...state,sortDirection, sortOrder}));
    }

    function handleShowProfile() {
        props.history.push('/profile');

    }

    const showLabels: boolean = state.modal.labels ? state.modal.labels.show : false;
    return <div className="page">
        <LabelEditModal show={showLabels}
                        labels={state.labels}
                        onCloseClick={() => handleModalClose('labels')}
                        onLabelChanged={(action: any, change: any) => handleLabelChanged(action, change)}/>
        <Header
            message={state.message}
            onSearchChange={(value: any) => handleSearchChange(value)}
            onShowLabelsClick={handleShowLabelsClick}
            onAddClick={() => handleAddClick()}
            onProfileClick={handleShowProfile}
            onSortChanged={handleSort}
            searchText={state.filter}
            sortDirection={state.sortDirection}
            sortOrder={state.sortOrder}
            showLabelsSelected={state.menu.shown === 'labels'}
        />
        <div className="main-container">
            <SideMenu
                labels={state.labels}
                selectedLabel={state.currentLabel}
                enabledMenus={state.menu}
                onEditLabelsClick={() => handleEditLabels()}
                onLabelClick={(label: any) => handleLabelSelected(label)}
            ></SideMenu>
            <div className="notes">
                {renderNotes()}
            </div>
        </div>
    </div>;
};
