import React from 'react';
import './Modal.css';

interface ModalProps {
    children: any[];
    onOutsideClick?: () => void;
    background: 'opaque' | 'transparent' | 'none'
}

export function Modal(props: ModalProps) {
    const renderHeader = () => <div className="modal-header">{props.children[0]}</div>;
    const renderBody = () => <div className="modal-body">{props.children[1]}</div>;
    const renderFooter = () => props.children[2] ? <div className="modal-footer">{props.children[2]}</div> : '';
    const modalClass = "modal-container "+ props.background;
    return <div className={modalClass} onClick={event => { event.stopPropagation(); (props.onOutsideClick && props.onOutsideClick()); }}>
        <div />
        <div className="modal" onClick={event => event.stopPropagation()} >
            {renderHeader()}
            {renderBody()}
            {renderFooter()}
        </div>
        <div />
    </div>;
}
