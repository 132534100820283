import {NoteService} from "../service/NoteService";
import {ItemService} from "../service/ItemService";
import {ItemDbo} from "../dbo/ItemDbo";
import {NoteDbo} from "../dbo/NoteDbo";
import {LabelDbo} from "../dbo/LabelDbo";
import {MenuState, ModalState} from "../state";
import {addEventListener, initEvents} from "../service/event";
import {hasLabel} from "./Notes";
import {StateUpdateIterator} from "../page/MainPage";
import {SortDirectionEnum, SortOrderEnum} from "../dbo/SortOrderEnum";

export interface State {
    state: 'initial' | 'loading' | 'loaded'
    message: { type: string, text: string } | null;
    items: ItemDbo[];
    filteredItems: ItemDbo[];
    filter: string;
    nextKey: number;
    currentLabel: LabelDbo | null;
    labels: LabelDbo[];
    menu: MenuState;
    modal: ModalState;
    error?: any;
    version: number;
    sortOrder: SortOrderEnum;
    sortDirection: SortDirectionEnum;
}

export type Pstate = Partial<State>;


export async function loadDataAsync(state: State): Promise<ItemDbo[]> {
    const notes = await NoteService.getAll();
    const loadedItems = notes.map(function (note: NoteDbo) {
        return {
            note,
            selected: false,
            unsaved: false,
            key: note.id || ''
        };
    });
    initEvents();
    addEventListener('note', (event: any) => updateNotes(state, event));
    return loadedItems;
}

export function setItems(items: ItemDbo[], filter: string, currentLabel: LabelDbo | null): Partial<State> {
    let filteredItems = items;
    if (currentLabel) {
        filteredItems = filteredItems.filter((item: any) => hasLabel(item, currentLabel));
    }
    if (filter) {
        filteredItems = filterItems(items, filter);
    }
    return {items, filter, filteredItems, currentLabel};
}


function recUpdate(item: any, path: any, value: any): any {
    if (path.length === 0) {
        return Object.assign({}, item, value);
    }
    if (item === undefined) {
        item = {};
    }
    const key = path[0];
    const child = recUpdate(item[key], path.slice(1), value);
    return {...item, [key]: child};
}


function update(item: any, path: string, value: any) {
    return recUpdate(item, path.split('.'), value);
}

function plainText(html: string) {
    var div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
}

export function filterItems(items: any[], filter: string) {
    const lowered = filter.toLocaleLowerCase();
    return items.filter(item => {
        /**
         * Always display currently selected item
         */
        if (item.selected) {
            return true;
        }
        if (item.note.title.toLocaleLowerCase().indexOf(lowered) >= 0) {
            return true;
        }
        const plain = plainText(item.note.content).toLocaleLowerCase();
        if (plain.indexOf(lowered) >= 0) {
            return true;
        }
        return false;
    });
}


function updateNotes(state: State, event: any): Partial<State> {
    let {filter, items, currentLabel} = state;
    switch (event.status) {
        case 'deleted':
            items = ItemService.removeByNoteId(items, event.note.id);
            break;
        case 'created':
            items = ItemService.create(items, event.note);
            break;
        case 'updated':
            items = ItemService.update(items, event.note, true);
            break;
        default:
            console.error("Unhandled note event", event.status);
            return {};
    }
    return setItems(items, filter, currentLabel);
}


export function selectItem(state: State, noteId: any): Partial<State> {
    let newItems: any[] = [];
    state.items.forEach(item => {
        const note = item.note;
        if (note.id === noteId) {
            newItems.push({...item, selected: true});
        } else if (item.selected) {
            newItems.push({...item, selected: false});
        } else {
            newItems.push(item);
        }
    });
    return setItems(newItems, state.filter, state.currentLabel);
}


export function deselectItem(state: State, noteId: any): Partial<State> {
    let newItems: any[] = [];
    state.items.forEach(item => {
        const note = item.note;
        if (note.id === noteId) {
            newItems.push({...item, selected: false});
        } else {
            newItems.push(item);
        }
    });
    return {items: newItems};
}


export function deselectItems(state: State): Partial<State> {
    let newItems: any[] = [];
    state.items.forEach(item => {
        if (item.selected) {
            newItems.push({...item, selected: false});
        } else {
            newItems.push(item);
        }
    });
    return {items: newItems};
}


export function newItem(state: State, options: { title: string | null, content: string | null }): Partial<State> {
    var items = state.items.slice();
    const key = String(state.nextKey);
    const item: ItemDbo = {
        key,
        note: {
            id: null,
            title: options.title || '',
            content: options.content || '',
            meta: {
                contentType: 'text/plain'
            },
            color: '',
            labels: [],
            creationDate: new Date(),
            modificationDate: new Date()
        },
        unsaved: true,
        selected: true
    };
    items.push(item);
    return setItems(items, state.filter, state.currentLabel);
}

function setDeletingFlag(state: State, note: any): Partial<State> {
    const items = [];
    for (const item of state.items) {
        if (item.note.id === note.id) {
            items.push({...item});
        } else {
            items.push(item);
        }
    }

    return setItems(items, state.filter, state.currentLabel);
}


async function* deleteSavedAsyncGen(state: State, note: any): AsyncIterableIterator<Partial<State>> {
    yield setDeletingFlag(state, note);
    try {
        console.debug("Deleting note");
        await NoteService.delete(note);
        yield deleteUnsaved(state, note);
    } catch (e) {
        const message = {
            type: 'error',
            text: e.toString()
        };
        yield {message};
    }

}

function deleteUnsaved(state: State, note: any): Partial<State> {
    console.debug("Now removing local item");
    console.debug("Currently have " + state.items.length + " items");
    const items = [];
    const filteredItems = [];
    for (const item of state.items) {
        if (item.note.id !== note.id) {
            items.push(item);
        }
    }
    for (const item of state.filteredItems) {
        if (item.note.id !== note.id) {
            filteredItems.push(item);
        }
    }
    return {items, filteredItems};
}


export async function* deleteItemAsyncGen(state: State, item: any): StateUpdateIterator {
    if (item.unsaved) {
        yield deleteUnsaved(state, item.note);
    } else {
        for await (let stateUpdate of deleteSavedAsyncGen(state, item.note)) {
            yield stateUpdate;
        }
    }
}

function hasData(note: any): boolean {
    return note.title || note.content;
}


export async function* saveItemAsyncGen(state: State, changedItem: ItemDbo, note: NoteDbo): AsyncIterator<Partial<State>, void, State> {
    changedItem = {...changedItem, note};
    let {items, filter, currentLabel} = state;
    const oldItem = ItemService.getItemByKey(items, changedItem.key);
    if (oldItem && ItemService.sameItem(changedItem, oldItem)) {
        console.log("Actually, the item has not changed, not gonna save it");
        console.log("Items are", oldItem, changedItem);
        changedItem.selected = false;
        changedItem.unsaved = false;
        let updates: Partial<State> = setItems(ItemService.removeByItemKey(items, changedItem.key), filter, currentLabel);
        yield updates;

    } else {
        let updates: Partial<State> = setItems(ItemService.removeByItemKey(items, changedItem.key), filter, currentLabel);

        state = yield updates;
        if (hasData(note)) {
            const updatedItem = update(changedItem, 'note', note);
            const savedNote = await NoteService.save(updatedItem.note);
            // refresh the value after the promise has returned
            let {items, filter, currentLabel} = state;
            let updates = setItems(ItemService.create(items, savedNote), filter, currentLabel);
            yield  updates
        } else {
            yield {};
        }

    }
}


export function searchText(state: State, text: string): Pstate {
    const {items, currentLabel} = state;
    return setItems(items, text, currentLabel);
}

export function toggleSelectedLabel(state: State, newLabel: any): Pstate {
    const {items, currentLabel, filter} = state;
    console.log(currentLabel, newLabel);
    if (currentLabel && newLabel.id === currentLabel.id) {
        newLabel = null;
    }
    return setItems(items, filter, newLabel);
}

export function closeModal(state: State, modalName: any): Pstate {
    const modal = {...state.modal, [modalName]: {show: false}};
    return {modal};
}


