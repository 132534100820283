import {LabelService} from "../service/Label";
import {removeLabelFromNotes} from "./Notes";
import {State} from "./action";
import {StateUpdateIterator} from "../page/MainPage";
import {NewLabelDbo} from "../dbo/LabelDbo";


async function createLabel(state: State, label: any): Promise<Partial<State>> {
    const result = await LabelService.save(label);
    const labels = state.labels.slice();
    labels.push(result);
    return {labels};
}


async function editLabel(state: State, updatedLabel: NewLabelDbo): Promise<Partial<State>>{
    const result = await LabelService.save(updatedLabel);
    const labels = [];
    for (let label of state.labels) {
        if (label.id === result.id) {
            labels.push(result);
        } else {
            labels.push(label);
        }
    }
    return {labels};
}

async function* deleteLabelAsyncGen(state: State, deletedLabel: any): StateUpdateIterator {
    await LabelService.delete(deletedLabel);
    const labels = state.labels.filter( label => label.id !== deletedLabel.id );
    // Now remove the label from all the notes
    yield { labels };
    yield removeLabelFromNotes(state, deletedLabel);
}


export async function* changeLabelAsyncGen(state: State, action: any, label:NewLabelDbo): StateUpdateIterator {
    switch (action) {
        case 'create':
            yield createLabel(state, label);
            break;
        case 'edit':
            yield editLabel(state, label);
            break;
        case 'delete':
            await deleteLabelAsyncGen(state, label);
            break;
        default:
            console.error("Invalid label operation " + action);
            yield state;
    }
}


export function showLabelsModal(state: State): Partial<State> {
    const modal = {...state.modal, labels: {show: true}};
    return { modal };

}
