function getCookieString(name) {
    const entries = document.cookie.split(';');
    for (const entry of entries) {
        if (entry.startsWith(name)) {
            return entry.trim().substring(name.length+1, entry.length);
        }
    }
    return null;
}


export function set(name, value, expirationHours) {
    var expires = "";
    if (expirationHours !== undefined) {
	var d = new Date();
	d.setTime(d.getTime() + (expirationHours * 60 * 60 * 1000));
	expires = ";expires="+d.toUTCString();
    }
    document.cookie = name + "=" + JSON.stringify(value) + expires + ";path=/";
}

export function unset(name) {
    set(name,"",0);
}

export function get(name) {
    name = name + "=";
    var cookie = getCookieString(name);
    if (!cookie) {
        return null;
    }
    return cookie;
}

// export function getExpireDate(name) {
//     const cookie = getCookieString

// };
