import React from 'react';
import './SideMenu.css';
import {MenuState} from "../../state";
import {LabelDbo} from "../../dbo/LabelDbo";

interface SideMenuProps {
    enabledMenus: MenuState;
    onEditLabelsClick: (event: any) => void;
    labels: LabelDbo[];
    selectedLabel: LabelDbo | null;
    onLabelClick: (label: LabelDbo) => void;
};


export function SideMenu(props: SideMenuProps) {
    function labelClass(label: LabelDbo) {
        if (props.selectedLabel && props.selectedLabel.id === label.id) {
            return "selected";
        } else {
            return "";
        }
    }

    function renderLabelsSubmenu() {
        switch (props.enabledMenus.shown) {
            case "labels":

                return <div className="menu-section">
                    <div className="menu-section-title clickable" onClick={props.onEditLabelsClick}><em>Edit...</em></div>
                    {props.labels.map(label => <button className={labelClass(label)} key={label.id}
                                                   onClick={() => props.onLabelClick(label)}>{label.description}
                    </button>)}
                </div>;
            case "settings":
            case "none":
            default:
                return null;
        }
    }

    if (props.enabledMenus.shown !== 'none') {
        return <div className="side-menu">
            {renderLabelsSubmenu()}
        </div>;
    } else {
        return null;
    }
}

