import React, {useEffect, useState} from "react";
import {BrowserRouter as Router, Route} from "react-router-dom";
import {MainPage} from "./page/MainPage";
import {ProfilePage} from "./page/ProfilePage";
import * as cookie from "./cookie";
import {Login} from "./component/login";
import {LoginService} from "./service/LoginService";
import {stopEvents} from "./service/event";

export interface AppState {
    state: 'logged-in' | 'logged-out' | 'do-login';
    login: string | null;
    error: string | undefined;
    loginOptions: LoginOptions | undefined;
}


const INITIAL_STATE: AppState = {
    state: cookie.get('connect.sid') ? 'logged-in' : 'logged-out',
    login: cookie.get('connect.sid'),
    error: undefined,
    loginOptions: undefined
};

interface LoginOptions {
    username: string,
    password: string,
    keepLoggedIn: string
}

export function App() {
    console.log("Initial state:", INITIAL_STATE);
    const [state, setState] = useState(INITIAL_STATE);
    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('message', event => {
                console.log("Received event of type", event.data.type);
                if (event.data.type === 'unauthorized') {
                    console.log('Unauthorized request');
                    setState( state => {
                       return {...state, state: 'logged-out', login: null};
                    });
                }
            });
        } else {
            console.error("ServiceWorker not found! Some things may not work properly");
        }
    }, []);
    function handleLogout() {
        stopEvents();
        LoginService.logout().then(function () {

            setState(INITIAL_STATE);
            console.log("Logged out");
        });


    }

    async function checkState(state: AppState) {
        if (state.state !== 'do-login') {
            return;
        }
        const options = state.loginOptions;
        if (!options) {
            return;
        }
        try {
            await LoginService.login(options.username, options.password, {keepLoggedIn: options.keepLoggedIn});
            console.log("Login successful");
            setState({...state, state: 'logged-in'})

        } catch (e) {
            console.error("Unable to login:", e);
            setState({...state, state: 'logged-out', error: e.error});
        }

    }

    useEffect(() => {
        checkState(state)
    }, [state]);


    function handleLogin(credentials: LoginOptions) {
        setState({...state, state: "do-login", loginOptions: credentials});
    }

    if (state.state === 'logged-in') {
        return <Router>
            <Route exact path="/" component={MainPage}/>
            <Route exact path="/profile" render={() => <ProfilePage onLogout={handleLogout}/>}/>
            <Route exact path="/note" component={MainPage}/>
            <Route exact path="/note/:id" component={MainPage}/>
        </Router>;
    } else {
        return <Login error={state.error} onSubmit={(credentials: any) => handleLogin(credentials)}/>;
    }

}
