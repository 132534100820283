import {HttpException} from '../exception.js';
import {LabelDbo, NewLabelDbo} from "../dbo/LabelDbo";

class LabelService {

    async getAll(): Promise<LabelDbo[]> {
        const result = await fetch('api/label');
        if (result.ok) {
            const json = await result.json();
            return json.labels;
        } else {
            throw new HttpException(result);
        }
    }

    async update(label: LabelDbo) {
        let result;
        const headers = {
            'Content-Type': 'application/json'
        };

        if (label.id) {
            result = await fetch(`api/label/${label.id}`, {
                method: "PUT",
                headers,
                body: JSON.stringify(label)
            });
        } else {
            result = await fetch(`api/label`, {
                method: "POST",
                headers,
                body: JSON.stringify(label)
            });
        }
        if (result.ok) {
            return result.json();
        } else {
            throw new HttpException(result);
        }
    }

    async save(label: NewLabelDbo) {
        let result;
        const headers = {
            'Content-Type': 'application/json'
        };

        result = await fetch(`api/label`, {
            method: "POST",
            headers,
            body: JSON.stringify(label)
        });
        if (result.ok) {
            return result.json();
        } else {
            throw new HttpException(result);
        }
    };

    async delete(label: LabelDbo) {
        const result = await fetch(`api/label/${label.id}`, {
            method: "DELETE"
        });
        if (result.ok) {
            return null;
        } else {
            console.log(result);
            throw new HttpException(result);
        }
    }
};

const instance = new LabelService();

export {instance as LabelService};
