import "normalize.css/normalize.css";
import React from 'react';
import ReactDOM from 'react-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './index.css';
import {App} from "./App";

import packageJson from '../package.json';

console.info(`Welcome to ${packageJson.name} version ${packageJson.version}`);

ReactDOM.render(<App/>,
    document.getElementById('root')
);


if ('serviceWorker' in navigator) {

    navigator.serviceWorker
        .register(new URL('/src/service-worker.js', import.meta.url), {
            type: 'module',
            scope: '/'
        })
        .then(function (reg) {
            console.log('Service Worker Registered');
        }).catch(function (e) {
        console.error("Unable to register service worker:", e);
    });
}

/* window.addEventListener('DOMContentLoaded', () => {
 *     const parsedUrl = new URL(window.location);
 *     console.log('Title shared: ' + parsedUrl.searchParams.get('title'));
 *     console.log('Text shared: ' + parsedUrl.searchParams.get('text'));
 *     console.log('URL shared: ' + parsedUrl.searchParams.get('url'));
 * });*/
