import React, {ChangeEvent, useState} from "react";
import {ProfileHeader} from "../component/profile-header/ProfileHeader";
import {useHistory} from "react-router-dom";
import packageJson from '../../package.json';
import {LoginService} from "../service/LoginService";

export interface ProfilePageProps {
    onLogout: () => void;
}

interface State {
    password: string;
    passwordAgain: string;
}

const INITIAL_STATE: State = {
    password: '',
    passwordAgain: ''
}

export function ProfilePage(props: ProfilePageProps) {
    const [state, setState] = useState(INITIAL_STATE);
    const history = useHistory();

    function handleBackClicked() {
        history.push("/");
    }

    function handlePasswordChanged(e: ChangeEvent<HTMLInputElement>) {
        const password = e.target.value;
        setState({...state, password});
    }

    function handlePasswordAgainChanged(e: ChangeEvent<HTMLInputElement>) {
        const passwordAgain = e.target.value;
        setState({...state, passwordAgain});
    }

    function handleLogoutClicked() {
        props.onLogout();
        history.push("/");
    }

    return <div>
        <ProfileHeader onBackClick={handleBackClicked}/>
        <div className="modal-container">
            <div className="modal">
                <div className='modal-header'>
                    Sticky Memo {packageJson.version}
                </div>
                <div className='modal-body'>
                    <div className="form-row">
                        <span className="label">User:</span>
                    </div>
                    <div className="form-row">
                        {LoginService.getUsername()}
                    </div>
                    <div className="form-row">
                        <span className="label">New Password:</span>
                    </div>
                    <div className="form-row">
                        <input id="password" type="password" value={state.password}
                               onChange={handlePasswordChanged}/>
                    </div>
                    <div className="form-row">
                        <span className="label">Confirm Password:</span>
                    </div>
                    <div className="form-row">
                        <input id="password-again" type="password" value={state.passwordAgain}
                               onChange={handlePasswordAgainChanged}/>
                    </div>
                    <div className="form-row">
                        {state.password !== state.passwordAgain ?
                            <div className="alert">Passwords do not match !</div> :
                            <div>&nbsp;</div>}
                    </div>
                </div>
                <div className="modal-footer">
                    <button>Update Password</button>
                    <button onClick={handleLogoutClicked}>Logout</button>
                </div>
            </div>
        </div>
    </div>;
}
