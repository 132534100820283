import React from 'react';
import './ProfileHeader.css';

interface HeaderProps {
    onBackClick: (event: any) => void;
}


export const ProfileHeader = function ({onBackClick}: HeaderProps) {

    // The searchbox goes first so that we can hide all the rest on mobile when we click on it just using CSS
    return <div className="profile-header">
        {/*<span className="main-title not-mobile">Sticky Memo</span>*/}
        {/*<span className="spacer"></span>*/}
        <button onClick={onBackClick}><i className='fa fa-fw fa-reply'/></button>
    </div>;
}


