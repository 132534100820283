import React, {CSSProperties} from 'react';
import './IconList.css';
import {IconDbo} from "../dbo/IconDbo";

interface IconListProps {
    icons: IconDbo[],
    style?: CSSProperties;
}

export function IconList(props: IconListProps) {
    const {icons, style} = props;
    const iconClass = (icon: IconDbo) => "clickable fas " + icon.icon;
    const iconStyle = (icon: IconDbo) => (icon.color ? {color: icon.color} : {});

    function callback(icon: IconDbo) {
        return function (event: any) {
            icon.callback(event);
            event.stopPropagation();
        }
    }

    function renderIcon(icon: IconDbo) {
        return <button key={icon.id}  onClick={callback(icon)}><i style={iconStyle(icon)} className={iconClass(icon)} /></button>;
    }

    return <div className="icon-list" style={style}> {icons.filter(i => i.enabled).map(renderIcon)} </div>
}
