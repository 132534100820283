import {State} from "./action";


export function hasLabel(item: any, label: any): boolean {
    return item.note.labels && item.note.labels.indexOf(label.id) >= 0;
}

export function removeLabelFromNotes(state: State, label: any): Partial<State> {
    let changed = false;
    const items = state.items.map( item=> {
        if (!item.note.labels) {
            return item;
        }
        const labels = (item.note.labels).filter( (labelId: any)  => labelId !== label.id );
        if (labels.length === item.note.labels.length) {
            return item;
        }
        // Ok, we removed the label, let's build a new item
        changed = true;
        const note = {...item.note, labels};
        return {...item, note};
    });
    if (changed) {
        return {items};
    } else {
        return {};
    }
}
