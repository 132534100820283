import {HttpException} from '../exception.js';


function createForm(username: string, password: string, options: any) {
    // const form = new FormData();
    // form.append("username", username);
    // form.append("password", password);
    // return form;
    let result = `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`;
    if (options.keepLoggedIn) {
        result = result + `&keep-logged-in=true`;
    }
    return result;
}

class LoginService {
    private username: string | undefined;

    async login(username: string, password: string, options: any) {
        console.log("Performing login");
        const result = await fetch("api/login", {
            body: createForm(username, password, options),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            method: "post"
        });
        if (!result.ok) {
            throw new HttpException(result);
        }
        this.username = username;
    }

    async logout() {
        const result = await fetch("api/logout", {
            method: "post"
        });
        if (!result.ok) {
            throw new HttpException(result);
        }
    }

    getUsername() {
        return this.username;
    }
}

const instance = new LoginService();

export {instance as LoginService};

