import React, {useEffect, useState} from 'react';
import './Header.css';
import {SearchBar} from "./SearchBar";
import { SortBar } from './SortBar';
import {SortDirectionEnum, SortOrderEnum} from "../../dbo/SortOrderEnum";

export interface HeaderEntry {
    spacer: boolean;
    icon: string;
    action: () => void;
}


interface HeaderProps {
    message: any;
    onAddClick: (event: any) => void;
    onSearchChange: (event: any) => void;
    onShowLabelsClick: (event: any) => void;
    onProfileClick: (event: any) => void;
    onSortChanged: (order: SortOrderEnum, direction: SortDirectionEnum) => void;
    searchText: string;
    showLabelsSelected: boolean;
    sortOrder: SortOrderEnum;
    sortDirection: SortDirectionEnum;

}

interface HeaderState {
    showingSearch: boolean;
    showingSort: boolean;
}

const HEADER_STATE_INITIAL_STATE: HeaderState = {
    showingSearch: false,
    showingSort: false
};

export function Header({
                                    message,
                                    onAddClick,
                                    onSearchChange,
                                    searchText,
                                    onShowLabelsClick,
                                    onSortChanged,
                                    onProfileClick,
    showLabelsSelected,
    sortDirection,
    sortOrder
                                }: HeaderProps) {
    const messageClassNames = function () {
        return message.type + " message";
    };

    const [headerState, setState] = useState(HEADER_STATE_INITIAL_STATE);

    function renderMessage() {
        if (message) {
            return <span className={messageClassNames()}>{message.text}</span>;
        } else {
            return '';
        }
    }

    function onSearchKeyPress(event: any) {
        if (event.key === 'Escape') {
            hideSearchField();
        }
    }

    function showSearchField() {
        setState({...headerState, showingSearch: true});
    }

    function hideSearchField() {
        setState({...headerState, showingSearch: false});
        onSearchChange('');
    }

    function hideSortBar() {
        setState({...headerState, showingSort: false});
    }

    function showSearchBar() {
        console.log("Showing search bar");
        setState({...headerState, showingSort: true});
    }

    useEffect(() => {
        function eventListener(event: KeyboardEvent) {
            if (event.key === 'f' && event.ctrlKey) {
                showSearchField();
                event.preventDefault();
            }
            if (event.key === 'l' && event.ctrlKey) {
                onShowLabelsClick(event);
                event.preventDefault();
            }
        }

        document.onkeydown = eventListener;

        return () => {
            document.removeEventListener('onkeydown', eventListener as any);
            console.log("Removed event listener", eventListener);
        }
        /* FIXME */
        /* eslint-disable-next-line react-hooks/exhaustive-deps  */
    }, []);

    if (headerState.showingSearch) {
        return <SearchBar onHideSearchField={hideSearchField} searchText={searchText}
                          onSearchKeyPress={onSearchKeyPress} onSearchChange={onSearchChange}/>
    } else if (headerState.showingSort) {
        return <SortBar currentDirection={sortDirection} currentOrder={sortOrder} onHideSortBar={hideSortBar}
        onSortChanged={onSortChanged}
        />
    } else {
        const showLabelsClass = showLabelsSelected ? 'selected': '';
        // The searchbox goes first so that we can hide all the rest on mobile when we click on it just using CSS
        return <div className="header">
            <button onClick={onAddClick}><b>+</b></button>
            <button onClick={showSearchField}><i className='fa fa-fw fa-search'/></button>
            <button className={showLabelsClass} onClick={onShowLabelsClick}><i className='fa fa-fw fa-tags'/></button>
            <button onClick={showSearchBar}><i className='fa fa-fw fa-sort'/></button>
            <span className="filler"/>
            <button onClick={onProfileClick}><i className='fa fa-fw fa-user-cog'/></button>
            {renderMessage()}

        </div>;
    }
}


