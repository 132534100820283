import React from 'react';
import './login.css';
import {Modal} from "./shared/Modal";


export class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            keepLoggedIn: false
        };
    }

    handleUsernameChange(event) {
        this.setState({...this.state, username: event.target.value});
    }

    handlePasswordChange(event) {
        this.setState({...this.state, password: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.onSubmit(this.state);
    }

    renderError() {
        if (this.props.error) {
            return <div className="form-row error"> Unable to login: {this.props.error}</div>;
        } else {
            return <div className="form-row">&nbsp;</div>;
        }

    }

    render() {
        return <div>
            <form onSubmit={e => this.handleSubmit(e)}>
                <Modal background="'opaque'">
                    <div>Please enter username and password</div>
                    <div>
                        <div className="form-row">
                            <span className="label">Name:</span>
                        </div>
                        <div className="form-row">
                            <input id="login-username" type="text" value={this.state.username}
                                   onChange={e => this.handleUsernameChange(e)}/>
                        </div>
                        <div className="form-row">
                            <span className="label">Password:</span>
                        </div>
                        <div className="form-row">
                            <input id="login-password" type="password" value={this.state.password}
                                   onChange={e => this.handlePasswordChange(e)}/>
                        </div>
                        <div className="form-row checkbox">
                            &nbsp;
                        </div>
                        <div className="form-row checkbox">
                            <input id="keep-logged" type="checkbox" onChange={() => {
                                this.setState({keepLoggedIn: !this.state.keepLoggedIn})
                            }} checked={this.state.keepLoggedIn}/>Keep me logged in
                        </div>
                        {this.renderError()}
                    </div>
                    <div>
                        <div className="button-row">
                            <button>Login</button>
                        </div>
                    </div>
                </Modal>
                <input type="submit" style={{display: 'none'}}/>
            </form>
        </div>
            ;


    }


}
