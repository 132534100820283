
const SpeechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition;
// const SpeechGrammarList =
//     window.SpeechGrammarList || window.webkitSpeechGrammarList;
// const SpeechRecognitionEvent =
//     window.SpeechRecognitionEvent || window.webkitSpeechRecognitionEvent;


let recognition: SpeechRecognition | undefined;

function startRecording(options : {
    onText:( text : string[] ) => void,
    onEnd: () => void } ): void {
    function setupRecognition() {
        recognition = new SpeechRecognition();
        recognition.lang = 'en-US';
        recognition.continuous = true;
        recognition.interimResults = true;

        recognition.onstart = function() {
            console.log('Speech recognition started');
        };

        recognition.onend = function() {
            console.log('Speech recognition ended');
            options.onEnd();
        };

        recognition.onresult = function(event) {
            const nr = event.results.length-1;
            if (nr < 1) {
                return;
            }
            const transcript = [];
            for (let i=0; i< event.results.length;++i) {
                transcript.push(event.results[i][0].transcript);
            }
            options.onText(transcript);
        };

        recognition.onerror = function(event) {
            console.error('Speech recognition error:', event.error);
        };

        return recognition;
    }




    if (recognition) {
        recognition.stop();
    }

    recognition = setupRecognition();

    console.log("recognition", recognition);
    recognition.start();
}

function stopRecording() {
    recognition?.stop();
}

function isSpeechRecognitionAvailable() {
    return !!SpeechRecognition;
}

export const SpeechService = { startRecording, stopRecording, isSpeechRecognitionAvailable };
